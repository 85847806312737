import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { InfluencersIcon } from '../assets';
import { Breadcrumbs } from '../components/common/Breadcrumbs';
import { UserForm } from '../components/users/userForm';
import { ROUTES } from '../config/routes/config';
import { GET_SINGLE_USER } from '../graphql/user/query';
import { useNotification } from '../hooks/useNotification';
import type { ResponseSingleUser } from '../types';

export const SingleInfluencer = () => {
  const { id } = useParams();
  const { t } = useTranslation('common');
  const navigate = useNavigate();
  const { notifyWarning } = useNotification();
  const { data, refetch } = useQuery<ResponseSingleUser>(
    // const { loading } = useQuery<ResSingleCampaignType>(
    GET_SINGLE_USER,
    {
      variables: {
        id,
      },
      onError: () => {
        // TODO: SHOW MISSING CAMPAIGN ID NOTIFICATION
        notifyWarning(t('global.errorSingleInfluencer'));
        navigate(ROUTES.INFLUENCERS);
      },
      skip: !id,
    },
  );
  return (
    <div className="w-full flex flex-col gap-y-5">
      <Breadcrumbs
        icon={<InfluencersIcon />}
        breadcrumbs={[
          {
            text: t('singleInfluencer.influencerBreadcrumps'),
            link: ROUTES.INFLUENCERS,
          },
          {
            text: data?.user?.data?.name || '',
          },
        ]}
      />
      <UserForm info={data?.user.data} refetch={refetch} />
    </div>
  );
};
