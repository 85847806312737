import { useMutation } from '@apollo/client';
import { useEffect } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { UPDATE_PIECE } from '../../../graphql/campaign/mutation';
import { useNotification } from '../../../hooks/useNotification';
import { usePrompt } from '../../../hooks/usePrompt';
import { Button } from '../../common/Button';
import { DatePickerInput } from '../../inputs/DatePicker';
import { ActionTypeCom } from '../content/ActionType';

export function ActionDate({
  piece,
  total,
  refetch,
  refectDates,
  indexAction,
}: {
  piece: any;
  total: number;
  refetch: any;
  refectDates: any;
  indexAction: number;
}) {
  const { t } = useTranslation('common');
  const {
    control,
    setValue,
    handleSubmit,
    reset,
    formState: { dirtyFields },
  } = useForm({
    defaultValues: {
      date: piece.publishingAt,
    },
  });
  const [updatePiece, { loading }] = useMutation(UPDATE_PIECE);
  const { notifySaveSuccess, notifySaveError } = useNotification();

  useEffect(() => {
    setValue('date', piece.publishingAt, {
      shouldDirty: false,
    });
    reset();
  }, [piece]);

  const handleSave: SubmitHandler<any> = (data) => {
    updatePiece({
      variables: {
        pieceId: piece.id,
        key: 'publishingAt',
        value: data.date,
      },
      onCompleted: () => {
        notifySaveSuccess();
        reset();
        refetch();
        refectDates();
      },
      onError: () => {
        notifySaveError();
      },
    });
  };

  usePrompt({
    isDirty: Object.keys(dirtyFields).length > 0,
  });

  return (
    <div>
      <ActionTypeCom
        index={indexAction}
        totalActions={total}
        platform={piece.platform}
        type={piece.type}
        iconSize="size-[20px]"
      />
      <form
        onSubmit={handleSubmit(handleSave)}
        className="flex flex-row gap-2.5"
      >
        <DatePickerInput
          // limitDate={new Date()}
          control={control}
          name="date"
          id={`actionDate${piece.id}`}
        ></DatePickerInput>
        <Button
          text={t('global.save')}
          style="light-blue"
          type="submit"
          loading={loading}
          customClassnames="!px-14 flex items-center justify-center"
        />
      </form>
    </div>
  );
}
