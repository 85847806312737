import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import type { DateTypeENG } from '../../types/date';

type ScheduledDateProp = {
  type: DateTypeENG;
  date: string;
  link: string;
};

function formatDate(dateString: string): string {
  // Definir los meses del año en inglés abreviados
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  // Dividir la cadena de fecha en partes (año, mes, día)
  const [, month, day] = dateString.split('-');

  // Convertir el mes a número (0-11) y obtener el nombre del mes abreviado
  const monthIndex = parseInt(month, 10) - 1;
  const monthName = months[monthIndex];

  // Devolver la fecha en el formato deseado
  return `${parseInt(day, 10)} ${monthName}`;
}

export const ScheduledDate = ({ type, date, link }: ScheduledDateProp) => {
  const { t } = useTranslation('common');
  return (
    <Link to={link} className="flex flex-col ">
      <div className="bg-influentia-light-blue flex items-center justify-center rounded-[76px] h-[24px] max-w-[74px] px-[10px] text-influentia-black text-xs font-medium">
        {formatDate(date)}
      </div>
      <span className="mt-1 text-white hover:text-influentia-light-blue text-sm font-medium">
        {t(`dateType.${type}`)}
      </span>
      <div className="h-px mt-1 bg-white"></div>
    </Link>
  );
};
