import { useMutation } from '@apollo/client';
import type { SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Link } from '../../../assets';
import { replaceIds, ROUTES } from '../../../config/routes/config';
import { UPDATE_PIECE } from '../../../graphql/campaign/mutation';
import { useNotification } from '../../../hooks/useNotification';
import { usePermissions } from '../../../hooks/usePermissions';
import type {
  ContentStatusType,
  PieceStatusType,
  PlatformType,
} from '../../../types';
import type { ActionType } from '../../../types/action';
import type { ContentType } from '../../../types/content';
import { urlRegex } from '../../../utils/utils';
import { Button } from '../../common/Button';
import { TextInput } from '../../inputs/TextInput';
import { ActionContentInfo } from './ActionContentInfo';
import { ActionTypeCom } from './ActionType';

type ActionInfoProps = {
  index: number;
  piece: {
    id: string;
    type: ActionType;
    status: PieceStatusType;
    platform: PlatformType;
    description: string;
    content: {
      id: string;
      type: ContentType;
      status: ContentStatusType;
    }[];
    link: string;
  };
  totalActions: number;
  refetch: any;
};

export function ActionInfo({
  index,
  totalActions,
  piece,
  refetch,
}: ActionInfoProps) {
  const { campaignId, id } = useParams();
  const [updatePiece, { loading }] = useMutation(UPDATE_PIECE);
  const { notifySaveSuccess, notifySaveError } = useNotification();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const handleSave: SubmitHandler<any> = (data) => {
    updatePiece({
      variables: {
        pieceId: piece.id,
        key: 'link',
        value: data.link,
      },
      onCompleted: () => {
        notifySaveSuccess();
        refetch();
      },
      onError: () => {
        notifySaveError();
      },
    });
  };
  const { t } = useTranslation('common');
  const { isBrand } = usePermissions();
  return (
    <div>
      <div className="mb-2.5">
        <ActionTypeCom
          index={index}
          totalActions={totalActions}
          pieceId={piece.id}
          refetch={refetch}
          actionStatus={piece.status}
          platform={piece.platform}
          type={piece.type}
          link={
            id &&
            campaignId &&
            replaceIds({
              url: ROUTES.SINGLE_CAMPAIGN_CONTENT,
              params: [
                {
                  campaignId,
                },
                {
                  influencerId: id,
                },
              ],
            })
          }
        />
      </div>
      {piece.description && !isBrand() && (
        <div className="my-2.5">
          <span className="text-xs text-white font-medium">
            {piece.description}
          </span>
        </div>
      )}
      {piece.status === 'published' && (
        <>
          {!piece.link && !isBrand() && (
            <div className="flex flex-col">
              <span>{t('singleCampaign.link')}</span>
              <div>
                <form
                  onSubmit={handleSubmit(handleSave)}
                  className="flex flex-row"
                >
                  <TextInput
                    register={register}
                    maxLength={200}
                    registerName="link"
                    validation={{
                      required: true,
                      pattern: { value: urlRegex },
                    }}
                    errors={errors.link}
                    type="text"
                  />
                  <Button
                    text={t('global.send')}
                    style="light-blue"
                    type="submit"
                    customClassnames="ml-[5px] h-[41px] w-[88px] items-center flex justify-center !font-bold"
                  />
                </form>
              </div>
            </div>
          )}
          {piece.link && (
            <div className="flex flex-row justify-between">
              <div className="flex flex-row gap-[6px] items-center w-full">
                <div className="bg-white size-[28px] rounded-full text-influentia-black flex items-center justify-center">
                  <Link />
                </div>
                <div className="flex flex-col w-full">
                  <span className="text-influentia-light-blue text-[10px] font-medium">
                    {t('singleCampaign.link')}
                  </span>
                  <div className="flex flex-row justify-between w-full">
                    <a
                      href={piece.link}
                      target="_blank"
                      className="text-sm font-medium text-white hover:text-influentia-light-blue"
                    >
                      {piece.link}
                    </a>
                    <div className="flex items-center justify-center">
                      <a
                        href={piece.link}
                        target="_blank"
                        className="text-sm font-medium text-white hover:text-influentia-light-blue"
                      >
                        <Link />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
      {piece.status !== 'published' &&
        piece.content &&
        piece.content.length > 0 && (
          <div className="flex flex-col gap-[10px]">
            {piece.content?.map((c) => (
              <ActionContentInfo
                link={
                  id &&
                  campaignId &&
                  replaceIds({
                    url: ROUTES.SINGLE_CAMPAIGN_CONTENT,
                    params: [
                      {
                        campaignId,
                      },
                      {
                        influencerId: id,
                      },
                    ],
                  })
                }
                key={c.id}
                type={c.type}
                status={c.status}
              />
            ))}
          </div>
        )}
    </div>
  );
}
