import { useRecoilValue } from 'recoil';

import { userAtom } from '../../../states/atoms';
import { SocialNetworkImage } from '../../images/SocialNetworkImage';
import CopyText from './CopyText';

export const PlatformInfo = ({ platformInfo }: { platformInfo: any }) => {
  const user = useRecoilValue(userAtom);
  return (
    <>
      <div className="flex flex-col my-5">
        <div className="mb-5 flex flex-row">
          {platformInfo &&
            platformInfo.platforms &&
            platformInfo.platforms
              .split(',')
              .map((platform: any, index: number) => (
                <div
                  key={Math.random() + index + platform}
                  className="flex flex-row gap-2.5 items-center"
                >
                  <SocialNetworkImage
                    size="size-[16px]"
                    socialNetwork={platform}
                  />
                  <span className="font-medium">{platform}</span>
                  {index < platformInfo.platforms.split(',').length - 1 && (
                    <span className="font-medium mr-2.5">+</span>
                  )}
                </div>
              ))}
        </div>
        <div className="flex flex-col">
          {platformInfo &&
            platformInfo.descriptions &&
            platformInfo.descriptions.map((description: any) => (
              <CopyText text={description} type="Description" />
            ))}
        </div>
        <div className="flex flex-col">
          {platformInfo &&
            platformInfo.disclaimers &&
            platformInfo.disclaimers.map((disclaimer: any) => (
              <CopyText text={disclaimer} type="Disclaimer" />
            ))}
        </div>
        <div className="flex flex-col">
          {platformInfo &&
            platformInfo.referrals &&
            platformInfo.referrals.map((referral: any) => (
              <CopyText
                text={referral.referral}
                type={referral.type}
                customTitle={referral.user.id !== user.id && referral.user.name}
                description={referral.description}
              />
            ))}
        </div>
      </div>
    </>
  );
};
