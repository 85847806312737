import { useMutation } from '@apollo/client';
import { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import {
  ArrowBackIcon,
  DeclineIcon,
  RefreshIcon,
  SendIcon,
  TickIcon,
} from '../../../assets';
import { replaceIds, ROUTES } from '../../../config/routes/config';
import { DELETE_FILE, UPDATE_CONTENT } from '../../../graphql/content/mutation';
import { useNotification } from '../../../hooks/useNotification';
import { usePermissions } from '../../../hooks/usePermissions';
import type { ContentStatusType } from '../../../types';
import type { ContentType } from '../../../types/content';
import { Button } from '../../common/Button';
import FileUploader from '../../common/FileUploader';
import { Warning } from '../../common/Warning';
import { TextInput } from '../../inputs/TextInput';
import { ActionContentInfo } from './ActionContentInfo';
import { PreviewVersion } from './PreviewVersion';
import { ScriptVersion } from './ScriptVersion';

interface ActionButtonProps {
  text: string;
  icon: React.ReactNode;
  onClick: () => void;
  loading?: boolean;
  roles?: boolean;
}

const ActionButton: React.FC<ActionButtonProps> = ({
  text,
  icon,
  onClick,
  loading,
}) => (
  <Button
    style="light-blue"
    text={text}
    customClassnames="flex items-center justify-center w-full"
    iconLeft={icon}
    loading={loading}
    onClick={onClick}
  />
);

interface ActionButtonsGroupProps {
  status: string;
  contentType: ContentType;
  contentId: string;
  fileUrl: string;
  refetch: () => void;
}

const ActionButtonsGroup: React.FC<ActionButtonsGroupProps> = ({
  status,
  contentType,
  contentId,
  fileUrl,
  refetch,
}) => {
  const navigate = useNavigate();
  const [updateContent, { loading }] = useMutation(UPDATE_CONTENT);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const { notifySaveSuccess, notifySaveError } = useNotification();
  const [loadingDecline, setLoadingDecline] = useState(false);
  const handleFile = (file: File) => {
    updateContent({
      variables: {
        file,
        contentId,
      },
      context: {
        headers: {
          'content-type': 'multipart/form-data',
          'x-apollo-operation-name': 'updateContent',
        },
      },
      onCompleted: () => {
        notifySaveSuccess();
        refetch();
      },
      onError: () => {
        notifySaveError();
      },
    });
  };
  const handleClick = (statusChange: string) => {
    setLoadingDecline(statusChange === 'declined');
    updateContent({
      variables: {
        status: statusChange,
        contentId,
      },
      context: {
        headers: {
          'x-apollo-operation-name': 'updateContent',
        },
      },
      onCompleted: () => {
        notifySaveSuccess();
        refetch();
      },
      onError: () => {
        notifySaveError();
      },
    });
  };

  const { t } = useTranslation('common');
  const { isInfluentia, isInfluencer, isBrand } = usePermissions();
  const actions: Record<string, ActionButtonProps[]> = {
    internal_validation: [
      {
        text: t('singleCampaign.internalValidation'),
        icon: <TickIcon className="size-[20px] text-dark-blue" />,
        onClick: () => handleClick('external_validation'),
        roles: isInfluentia(),
        loading: loading && !loadingDecline,
      },
      {
        text:
          contentType === 'Script'
            ? t('singleCampaign.declineScript')
            : t('singleCampaign.declinePreview'),
        icon: <DeclineIcon className="size-[20px] text-dark-blue" />,
        onClick: () => handleClick('declined'),
        roles: isInfluentia(),
        loading: loading && loadingDecline,
      },
    ],
    modification_pending: [
      {
        text: fileUrl
          ? t('singleCampaign.uploadNewVersion')
          : t('singleCampaign.sendNewVersion'),
        icon: fileUrl ? (
          <RefreshIcon className="size-[20px]" />
        ) : (
          <SendIcon className="size-[20px]" />
        ),
        onClick: () => {
          if (fileUrl && fileInputRef.current) {
            fileInputRef.current.click();
          } else {
            handleClick('internal_validation');
          }
        },
        roles: isInfluentia() || isInfluencer(),
        loading: loading && !loadingDecline,
      },
    ],
    external_validation: [
      {
        // eslint-disable-next-line no-nested-ternary
        text: isInfluentia()
          ? t('singleCampaign.externalValidation')
          : contentType === 'Script'
            ? t('singleCampaign.validateScript')
            : t('singleCampaign.validatePreview'),
        icon: <TickIcon className="size-[20px] text-dark-blue" />,
        onClick: () => handleClick('validated'),
        roles: isInfluentia() || isBrand(),
        loading: loading && !loadingDecline,
      },
      {
        text: isInfluentia()
          ? t('singleCampaign.declineAsBrand')
          : t('singleCampaign.declineScript'),
        icon: <DeclineIcon className="size-[20px] text-dark-blue" />,
        onClick: () => handleClick('declined'),
        loading: loading && loadingDecline,
        roles: isInfluentia() || isBrand(),
      },
    ],
    validated: [
      {
        text: t('singleCampaign.reValidate'),
        icon: <ArrowBackIcon className="size-[20px]" />,
        onClick: () => handleClick('revalidate'),
        loading: loading && !loadingDecline,
        roles: isInfluentia(),
      },
    ],
  };
  const count = actions[status]?.length;
  return (
    <div className={`flex ${count <= 1 ? 'mt-1' : 'flex-row mt-1 gap-1.5'}`}>
      {actions[status]?.map(
        (action, index) =>
          action.roles && <ActionButton key={index} {...action} />,
      )}
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={(e) => {
          if (e.target.files && e.target.files[0]) {
            handleFile(e.target.files[0]);
          }
        }}
      />
    </div>
  );
};

interface ContentSectionProps {
  contentStatus: ContentStatusType;
  contentLink: string | undefined;
  contentId: string;
  contentType: ContentType;
  refetch: () => void;
  files?: { id: string; url: string; version: number }[];
}

const ContentSection: React.FC<ContentSectionProps> = ({
  contentStatus,
  contentLink,
  contentId,
  contentType,
  files,
  refetch,
}) => {
  const [deleteFile] = useMutation(DELETE_FILE);
  const [updateContent] = useMutation(UPDATE_CONTENT);
  const { notifySaveSuccess, notifySaveError } = useNotification();

  const deleteFileHandler = (fileId: string) => {
    deleteFile({
      variables: {
        fileId,
      },
      onCompleted: () => {
        notifySaveSuccess();
        refetch();
      },
      onError: () => {
        notifySaveError();
      },
    });
  };
  const handleFile = () => {
    updateContent({
      variables: {
        link: null,
        contentId,
      },
      context: {
        headers: {
          'x-apollo-operation-name': 'updateContent',
        },
      },
      onCompleted: () => {
        notifySaveSuccess();
        refetch();
      },
      onError: () => {
        notifySaveError();
      },
    });
  };
  return (
    <>
      {contentType === 'Script' ? (
        <>
          {files &&
            files.length > 0 &&
            files?.map((file) => (
              <ScriptVersion
                key={file.id}
                version={file.version}
                contentLink={contentLink}
                fileUrl={file.url}
                contentStatus={contentStatus}
                deleteContent={() => deleteFileHandler(file.id)}
              />
            ))}
          {files && files.length === 0 && contentLink && (
            <ScriptVersion
              version={1}
              contentLink={contentLink}
              contentStatus={contentStatus}
              deleteContent={() => handleFile()}
            />
          )}
        </>
      ) : (
        <>
          {((files && files.length > 0) || contentLink) && (
            <PreviewVersion
              deleteContent={() => {
                if (files && files.length > 0) {
                  deleteFileHandler(files[0].id);
                } else if (contentLink) {
                  handleFile();
                }
              }}
              contentLink={contentLink}
              fileUrl={files && files.length > 0 ? files[0].url : ''}
              contentStatus={contentStatus}
            />
          )}
        </>
      )}
      <ActionButtonsGroup
        status={contentStatus}
        fileUrl={files && files.length > 0 ? files[0].url : ''}
        contentType={contentType}
        contentId={contentId}
        refetch={refetch}
      />
    </>
  );
};

type ActionContentProps = {
  contentType: ContentType;
  contentId: string;
  piece: any;
  contentStatus: ContentStatusType;
  contentLink?: string;
  files?: { id: string; url: string; version: number }[];
  refetch: () => void;
  finalLine?: boolean;
};
export function ActionContent({
  contentType,
  contentStatus,
  contentId,
  contentLink,
  files,
  piece,
  refetch,
  finalLine,
}: ActionContentProps) {
  const navigate = useNavigate();
  const { campaignId, influencerId } = useParams();
  const { t } = useTranslation('common');
  const { isBrand } = usePermissions();
  const [link, setLink] = useState<string>('');
  const [updateContent, { loading }] = useMutation(UPDATE_CONTENT);

  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();
  const { notifySaveSuccess, notifySaveError } = useNotification();
  const handleFile = (file: File) => {
    updateContent({
      variables: {
        file,
        contentId,
      },
      context: {
        headers: {
          'content-type': 'multipart/form-data',
          'x-apollo-operation-name': 'updateContent',
        },
      },
      onCompleted: () => {
        notifySaveSuccess();
        refetch();
      },
      onError: () => {
        notifySaveError();
      },
    });
  };
  const ensureHttps = (url: string) => {
    if (!url.startsWith('https://')) {
      return `https://${url}`;
    }
    return url;
  };
  const handleSave = (data: any) => {
    clearErrors();
    let linkToSend = data.linkPreview || data.linkScript;
    linkToSend = linkToSend ? ensureHttps(linkToSend) : '';
    if (!linkToSend) {
      const key = Object.keys(data)[0];
      setError(key, {
        type: 'required',
        message: t('singleCampaign.urlRequired'),
      });
      return;
    }
    updateContent({
      variables: {
        link: linkToSend,
        contentId,
      },
      context: {
        headers: {
          'x-apollo-operation-name': 'updateContent',
        },
      },
      onCompleted: () => {
        notifySaveSuccess();
        refetch();
      },
      onError: () => {
        notifySaveError();
      },
    });
  };

  return (
    <div className="">
      <div className="mb-1">
        <ActionContentInfo
          type={contentType}
          status={contentStatus}
          className="bg-invisible text-white"
          classNameIcons="size-[16px]"
        />
      </div>
      {contentStatus === 'upload_pending' &&
        contentType === 'Script' &&
        piece.status === 'tobe_scheduled' &&
        !piece.paused &&
        !isBrand() && (
          <div className="min-h-[180px] size-full flex flex-col gap-y-2 items-center justify-center">
            <div className="flex  text-center text-light-blue italic text-[14px]">
              {t('singleCampaign.scriptNoScheduled')}
            </div>
            {influencerId && campaignId && (
              <Button
                style="black"
                text={t('singleCampaign.scheduleScript')}
                onClick={() => {
                  navigate(
                    replaceIds({
                      url: ROUTES.SINGLE_CAMPAIGN_DATES,
                      params: [{ campaignId }, { influencerId }],
                    }),
                  );
                }}
              />
            )}
          </div>
        )}

      {contentStatus === 'upload_pending' &&
        contentType === 'Script' &&
        piece.paused && (
          <div className="min-h-[180px] h-full flex items-center justify-center text-center text-light-blue italic text-[14px]">
            {t('singleCampaign.paused')}
          </div>
        )}

      {contentStatus === 'upload_pending' &&
        contentType === 'Script' &&
        piece.status === 'script_upload' &&
        !piece.paused &&
        !isBrand() && (
          <>
            {/* <div>
              <FileUploader
                loading={loading}
                submitFile={handleFile}
                fileType={contentType}
              />
              <div className="w-full justify-start items-center gap-5 inline-flex">
                <div className="grow basis-0 h-px bg-light-blue" />
                <div className="text-light-blue text-sm font-normal">o</div>
                <div className="grow basis-0 h-px bg-light-blue" />
              </div>
            </div> */}
            <div className="flex flex-col gap-2">
              <span className="font-inter text-sm text-light-blue">
                {t('singleCampaign.addGoogleDocs')}
              </span>
              <div>
                <form
                  className="flex flex-row items-center justify-center gap-1.5"
                  onSubmit={handleSubmit(handleSave)}
                >
                  <TextInput
                    type="text"
                    max={255}
                    maxLength={255}
                    extraClassname="h-[32px]"
                    selectedValue={link}
                    register={register}
                    registerName="linkScript"
                    errors={errors.linkScript}
                  />
                  <Button
                    style="light-blue"
                    text={t('global.post')}
                    loading={loading}
                    customClassnames="flex items-center justify-center"
                    type="submit"
                  />
                </form>
                <Warning warning={t('singleCampaign.permissionsWarning')} />
              </div>
            </div>
          </>
        )}
      {contentStatus !== 'external_validation' &&
        contentStatus !== 'validated' &&
        contentType === 'Script' &&
        isBrand() && (
          <>
            <div className="min-h-[180px] h-full flex items-center justify-center text-center text-light-blue italic text-[14px]">
              {t('singleCampaign.scriptProposalPending')}
            </div>
          </>
        )}
      {contentStatus === 'upload_pending' &&
        contentType === 'Preview' &&
        piece.status !== 'preview_upload' &&
        !piece.paused &&
        !isBrand() && (
          <div className="min-h-[180px] h-full flex items-center justify-center text-center text-light-blue italic text-[14px]">
            {piece.hasScript && t('singleCampaign.previewNoUploadScript')}
            {!piece.hasScript && t('singleCampaign.previewNoUpload')}
          </div>
        )}
      {contentStatus === 'upload_pending' &&
        contentType === 'Preview' &&
        piece.paused && (
          <div className="min-h-[180px] h-full flex items-center justify-center text-center text-light-blue italic text-[14px]">
            {t('singleCampaign.paused')}
          </div>
        )}
      {contentStatus === 'upload_pending' &&
        contentType === 'Preview' &&
        piece.status === 'preview_upload' &&
        !isBrand() && (
          <>
            <div>
              <FileUploader
                loading={loading}
                submitFile={handleFile}
                fileType={contentType}
              />
              <div className="w-full justify-start items-center gap-5 inline-flex">
                <div className="grow basis-0 h-px bg-light-blue" />
                <div className="text-light-blue text-sm font-normal">o</div>
                <div className="grow basis-0 h-px bg-light-blue" />
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <span className="font-inter text-sm text-light-blue">
                {t('singleCampaign.addWeTransfer')}
              </span>
              <div>
                <form
                  className="flex flex-row items-center justify-center gap-1.5"
                  onSubmit={handleSubmit(handleSave)}
                >
                  <TextInput
                    type="text"
                    extraClassname="h-[32px]"
                    selectedValue={link}
                    register={register}
                    registerName="linkPreview"
                    errors={errors.linkPreview}
                  />
                  <Button
                    style="light-blue"
                    type="submit"
                    text={t('global.post')}
                    customClassnames="flex items-center justify-center"
                  />
                </form>
                <Warning warning={t('singleCampaign.permissionsWarning')} />
              </div>
            </div>
          </>
        )}
      {[
        'internal_validation',
        'modification_pending',
        'external_validation',
        'validated',
      ].includes(contentStatus) &&
        !isBrand() && (
          <ContentSection
            contentStatus={contentStatus}
            contentId={contentId}
            contentLink={contentLink}
            contentType={contentType}
            files={files}
            refetch={refetch}
          />
        )}
      {['external_validation', 'validated'].includes(contentStatus) &&
        isBrand() && (
          <ContentSection
            contentStatus={contentStatus}
            contentId={contentId}
            contentLink={contentLink}
            contentType={contentType}
            files={files}
            refetch={refetch}
          />
        )}
      {contentStatus !== 'external_validation' &&
        contentStatus !== 'validated' &&
        contentType === 'Preview' &&
        isBrand() && (
          <>
            <div className="min-h-[180px] h-full flex items-center justify-center text-center text-light-blue italic text-[14px]">
              {t('singleCampaign.previewProposalPending')}
            </div>
          </>
        )}

      {finalLine && <div className="h-px bg-influentia-black my-5 px-2"></div>}
    </div>
  );
}
