import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import type {
  ContentStatusType,
  PieceStatusType,
  PlatformType,
} from '../../../types';
import type { ActionType } from '../../../types/action';
import type { ContentType } from '../../../types/content';
import { UsersImages } from '../../images/UserImages';
import { ActionTypeCom } from './ActionType';

type TodayPublicationProps = {
  profilePicture: string;
  pieces: {
    id: string;
    type: ActionType;
    status: PieceStatusType;
    platform: PlatformType;
    description: string;
    publishingAt: string; // Add publishingAt here
    content: {
      id: string;
      type: ContentType;
      status: ContentStatusType;
    }[];
    link: string;
  }[];
  name: string;
  todayDate: string;
  link: string;
};

export function TodayPublication({
  profilePicture,
  pieces,
  name,
  todayDate,
  link,
}: TodayPublicationProps) {
  const { t } = useTranslation('common');
  return (
    <>
      {pieces.length > 0 && (
        <>
          {pieces.map((piece, index) => {
            // Check if the condition is met
            if (piece.publishingAt === todayDate) {
              return (
                <div key={piece.id + index}>
                  <div className="flex flex-row justify-between">
                    <div className="flex flex-row gap-[5px]">
                      <UsersImages
                        userCount={1}
                        userImages={[profilePicture]}
                        size="size-[24px]"
                      />
                      <Link
                        to={link}
                        className="flex items-center justify-center hover:text-influentia-light-blue"
                      >
                        <span className="text-[14px] font-bold">{name}</span>
                      </Link>
                    </div>
                    <ActionTypeCom
                      showStatus={false}
                      key={piece.id}
                      extraActions={false}
                      index={index + 1}
                      totalActions={pieces.length}
                      pieceId={piece.id}
                      actionStatus={piece.status}
                      platform={piece.platform}
                      type={piece.type}
                    />
                  </div>
                  <div className="bg-white h-px w-full mt-[5px]"></div>
                </div>
              );
            }
            return null; // Return null if the condition is not met
          })}
        </>
      )}
    </>
  );
}

/*
            <div>{piece.publishingAt}</div>;

*/
