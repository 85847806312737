import { useTranslation } from 'react-i18next';

import { BarsIcon, ChatIcon, Link, LockIcon } from '../../../assets';
import { CopyButton } from '../../common/CopyButton';

export default function CopyText({
  type,
  text,
  customTitle,
  description,
}: {
  type: 'Code' | 'Link' | 'Disclaimer' | 'Description';
  text: string;
  customTitle?: string;
  description?: string;
}) {
  const { t } = useTranslation('common');
  let icon = <></>;
  let title = '';
  if (type === 'Code') {
    icon = <BarsIcon className="size-[16px]" />;
    title = t('singleCampaign.referrerCode');
  }
  if (type === 'Link') {
    title = t('singleCampaign.referrerLink');
    icon = <Link className="size-[16px] text-influentia-black" />;
  }
  if (type === 'Disclaimer') {
    title = t('singleCampaign.disclaimer');
    icon = <LockIcon className="size-[16px]" />;
  }
  if (type === 'Description') {
    title = t('singleCampaign.description');
    icon = <ChatIcon className="size-[16px]" />;
  }
  return (
    <>
      {text && (
        <div className="flex flex-row justify-between items-center">
          <div className="flex flex-row gap-1.5">
            <div className="bg-white rounded-full size-[28px] p-1.5">
              {icon}
            </div>
            <div className="flex flex-col">
              <span className="text-[10px] text-influentia-light-blue font-medium">
                {title} {customTitle && `(${customTitle})`}
              </span>
              {type !== 'Link' && (
                <span className="text-[14px] font-medium max-w-[350px] truncate">
                  {text}
                </span>
              )}
              {type === 'Link' && (
                <a
                  href={text.startsWith('https://') ? text : `https://${text}`}
                  target="_blank"
                  rel="noreferrer noopener"
                  className="text-[14px] font-medium max-w-[350px] truncate underline"
                >
                  {text}
                </a>
              )}
              {description && (
                <span className="text-sm text-white/80">{description}</span>
              )}
            </div>
          </div>
          <CopyButton text={text} />
        </div>
      )}
    </>
  );
}
