import { useMutation } from '@apollo/client';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DropdownIcon, PauseIcon, PlayIcon } from '../../../assets';
import { Confirm } from '../../../context/confirm/confirm';
import { UPDATE_PIECE } from '../../../graphql/campaign/mutation';
import { useNotification } from '../../../hooks/useNotification';
import { usePermissions } from '../../../hooks/usePermissions';
import { StatusText } from '../../common/StatusText';
import { ActionContent } from './ActionContent';
import { ActionTypeCom } from './ActionType';
import { TimeLine } from './TimeLine';

export function ActionDropDown({
  piece,
  total,
  refetch,
  indexAction,
}: {
  piece: any;
  total: number;
  indexAction: number;
  refetch: any;
}) {
  const { t } = useTranslation('common');
  const [opened, setOpened] = useState(piece.status !== 'published');
  const toggleDropdown = () => {
    setOpened(!opened);
  };
  const { isInfluentia } = usePermissions();
  const [updatePiece, { loading }] = useMutation(UPDATE_PIECE);
  const { notifySaveSuccess, notifySaveError } = useNotification();
  const [open, setOpen] = useState(false);

  const handleSave = (state: string) => {
    updatePiece({
      variables: {
        pieceId: piece.id,
        key: 'paused',
        value: state,
      },
      onCompleted: () => {
        notifySaveSuccess();
        refetch();
      },
      onError: () => {
        notifySaveError();
      },
    });
  };

  return (
    <div id={`content-${indexAction}`} className="flex flex-col mb-7 w-full">
      <Confirm
        open={open}
        setOpen={setOpen}
        onConfirm={() => handleSave(String(!piece.paused))}
        type="info"
        title={piece.paused ? t('global.titlePlay') : t('global.titlePause')}
        subtitle={
          piece.paused ? t('global.subTitlePlay') : t('global.subTitlePause')
        }
        confirmText={
          piece.paused ? t('global.confirmPlay') : t('global.confirmPause')
        }
        cancelText={t('global.cancel')}
      />
      <div className="bg-dark-blue rounded-[5px] px-[5px] py-[2px] size-full">
        <div className="flex lg:flex-row flex-col justify-between items-center">
          <ActionTypeCom
            index={indexAction}
            totalActions={total}
            platform={piece.platform}
            type={piece.type}
            iconSize="size-[20px]"
          />
          <div className="flex lg:flex-row flex-col items-center font-medium gap-1">
            {piece.publishingAt && (
              <>
                <span>{t('global.publication')}:</span>
                <span>{piece.publishingAt}</span>
              </>
            )}
            <div className="ml-[10px] flex items-center text-white gap-[10px]">
              {piece && !piece.paused && (
                <>
                  <StatusText type="action" status={piece.status} />
                  <button
                    onClick={() => setOpen(true)}
                    className={`${!isInfluentia() && 'hidden'}`}
                  >
                    <div className="size-[26px] rounded-full bg-red-status flex items-center justify-center">
                      <PauseIcon className="size-[14px]" />
                    </div>
                  </button>
                </>
              )}
              {piece && piece.paused && (
                <>
                  <StatusText type="action" status={'paused'} />
                  <button
                    onClick={() => setOpen(true)}
                    className={`${!isInfluentia() && 'hidden'}`}
                  >
                    <div className="size-[26px] rounded-full bg-influentia-light-blue flex items-center justify-center">
                      <PlayIcon className="size-[14px]" />
                    </div>
                  </button>
                </>
              )}

              <div
                onClick={toggleDropdown}
                className={`cursor-pointer ${opened && 'rotate-180'}`}
              >
                <DropdownIcon />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`px-1 size-full  ${
          opened ? 'animate-dropdown-open' : 'animate-dropdown-close'
        }`}
      >
        <div className="bg-dark-blue mt-[8px] rounded-[5px] p-[10px]">
          <div className="flex lg:flex-row flex-col size-full">
            <div className="w-full">
              {piece &&
                piece.content &&
                piece.content.length > 0 &&
                piece.content.map((content: any, index: number) => (
                  <div key={piece.id + index}>
                    <ActionContent
                      contentType={content.type}
                      contentStatus={content.status}
                      contentLink={content.link}
                      contentId={content.id}
                      piece={piece}
                      files={content.files}
                      refetch={refetch}
                      finalLine={
                        piece.content.length > 1 &&
                        index + 1 < piece.content.length
                      }
                    />
                  </div>
                ))}
              {piece &&
                piece.content &&
                piece.status === 'tobe_scheduled' &&
                piece.content.length === 0 && (
                  <div className="min-h-[180px] h-full flex items-center justify-center text-center text-light-blue italic text-[14px]">
                    {t('singleCampaign.noContent')}
                  </div>
                )}
              {piece &&
                piece.content &&
                piece.status === 'scheduled' &&
                piece.content.length === 0 && (
                  <div className="min-h-[180px] h-full flex items-center justify-center text-center text-light-blue italic text-[14px]">
                    {t('singleCampaign.noContentScheduled')}
                  </div>
                )}
            </div>
            <div className="grow w-full flex lg:items-start lg:justify-normal items-center justify-center lg:mt-0 mt-5 lg:ml-5">
              <TimeLine
                timeline={piece.timeline}
                refetch={refetch}
                pieceId={piece.id}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
