import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import type { PieceStatusType, PlatformType } from '../../../types';
import type { ActionType } from '../../../types/action';
import { StatusText } from '../../common/StatusText';
import { UsersImages } from '../../images/UserImages';
import { ActionTypeCom } from './ActionType';

type PendingActionsProps = {
  profilePicture: string;
  pieces: {
    id: string;
    type: ActionType;
    status: PieceStatusType;
    platform: PlatformType;
    description: string;
    link: string;
  }[];
  status: any;
  name: string;
  link: string;
};

export function PendingActions({
  profilePicture,
  pieces,
  status,
  name,
  link,
}: PendingActionsProps) {
  const { t } = useTranslation('common');
  return (
    <>
      {pieces.length > 0 && (
        <div>
          <div className="flex flex-row justify-between">
            <div className="flex flex-row gap-[5px]">
              <UsersImages
                userCount={1}
                userImages={[profilePicture]}
                size="size-[24px]"
              />
              <Link
                to={link}
                className="flex items-center justify-center hover:text-influentia-light-blue"
              >
                <span className="text-[14px] font-bold">{name}</span>
              </Link>
            </div>
            <StatusText type="influencer" status={status} />
          </div>
          <div className="mt-4 flex flex-col gap-[5px]">
            {pieces.map((piece, index) => (
              <ActionTypeCom
                key={piece.id}
                extraActions={false}
                index={index + 1}
                totalActions={pieces.length}
                pieceId={piece.id}
                actionStatus={piece.status}
                platform={piece.platform}
                type={piece.type}
                link={link}
              />
            ))}
            <div className="bg-white h-px w-full mt-[15px]"></div>
          </div>
        </div>
      )}
    </>
  );
}
